import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useHistory, useLocation } from "react-router-dom";
import { selectAccessToken } from "../util/selectors";
import { useAppSelector } from "@/redux/store";
import { useRouteSlug } from "@/hooks";

const withAuthenticationChecking = Component => {
  const EnhancedComponent = props => {
    const history = useHistory();
    const location = useLocation();
    const accessToken = useAppSelector(selectAccessToken);
    const routeSlug = useRouteSlug();
    const pageData = useAppSelector(state => state.dynamicPage.data);

    if (!accessToken && pageData?.data?.authorization) {
      history.replace(
        `/${routeSlug}/login/?${location.pathname.replace(
          `/${routeSlug}/`,
          ""
        )}${location.search}`
      );
      return null;
    }
    return <Component {...props} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withAuthenticationChecking;
