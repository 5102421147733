import React, { FC, useEffect, useState } from "react";
import Loadable from "@loadable/component";
import ProductDefaultGif from "@/images/product_default.gif";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { hidePageLoader, showPageLoader } from "@/redux/actions/common.action";

const ImageLoader = () => {
  return (
    <img
      style={{ display: "none" }}
      src={ProductDefaultGif}
      alt="Product Placeholder Gif"
    />
  );
};

const PageLoaderHandler = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPageLoader());
    return () => {
      dispatch(hidePageLoader());
    };
  }, []);

  return null;
};

const Fallback: FC = () => (
  <div>
    <PageLoaderHandler />
    <ImageLoader />
  </div>
);

const ProductListingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "product_listing_page" */ "./productListingPage"
    ),
  { fallback: <Fallback /> }
);

export default ProductListingPage;
